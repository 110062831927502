.about-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
    height: auto; /* Adjusted height to auto for better responsiveness */
}

.property {
    --bg-angle: 0deg;
    --box-size: 150px; /* Set the size of the square box */
}

/* Define animation to rotate the gradient */
@keyframes spin {
    to {
        --bg-angle: 360deg;
    }
}

.faq-content {
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    max-width: 1090px;
    width: 90%;
    margin-bottom: 30px;
    text-align: center;
    animation:
        spin 2.5s infinite linear running,
        fadeIn 1s ease; /* Add fade-in animation */
}

.faq-content {
    /* Background colors don’t work with `background-origin`, so use a gradient. */
    background:
        linear-gradient(
                to bottom,
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 1)
            )
            padding-box,
        /* ends at inner border edges */
            conic-gradient(
                from var(--bg-angle) at center,
                transparent,
                #0a66c2,
                #103783,
                transparent,
                transparent,
                transparent
            )
            border-box; /* extends to outer border edges */

    /* a clear border lets the background gradient shine through */
    border: 3px solid transparent;
}

.contact-content {
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    width: 90%;
    max-width: 1100px;
    text-align: center;
    margin-bottom: 30px;

    animation: fadeIn 1s ease; /* Add fade-in animation */
}

.contact-content a:hover {
    color: #0a66c2;
    transition: ease 0.4s;
}

.team-content {
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    width: 90%;
    max-width: 1100px;
    text-align: center;
    animation: fadeIn 1s ease; /* Add fade-in animation */
}

.team-members {
    display: flex;
    flex-wrap: wrap; /* Allow flex items to wrap to a new line */
    justify-content: center;
    padding-bottom: 10px;
}

.team-member {
    flex: 1 1 calc(50% - 50px); /* Takes up 50% of the container width minus margin */
    max-width: 400px; /* Ensures the width doesn't exceed 400px */
    min-width: 200px;
    width: 100%;
    margin: 10px; /* Space between team members */
    padding: 10px;
    background-color: #fff; /* Background color */
    border-radius: 15px;
    border: 1px solid #ccc;
    transition: all 0.3s ease;
}


.team-member:hover {
    border: 1px solid #9bafd9;
}

.team-member p {
    margin: 0;
}

.team-member a:hover {
    text-decoration: underline;
}

.linkedin-icon {
    font-size: 45px;
    color: #0a66c2;
}

.team-link {
    text-decoration: underline;
    color: #424242;
    cursor: pointer;
}

@media (max-width: 600px) {
  .faq-content, .contact-content, .team-content {
      width: 80%;
  }
}

.team-logo {
  padding-top: 10px;
  margin-bottom: -10px;
  height: 8vh;
}