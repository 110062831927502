.watTitleContainer {
    position: relative;
    margin: 120px auto 1rem;
    width: 90%;
    max-width: 1120px;
    height: max-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    overflow: hidden;
    animation:
        spin 2.5s infinite linear running,
        fadeIn 1s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Define custom property for background angle */
@property --bg-angle {
    inherits: false;
    initial-value: 0deg;
    syntax: '<angle>';
}

/* Define animation to rotate the gradient */
@keyframes spin {
    to {
        --bg-angle: 360deg;
    }
}

.watTitleContainer {
    /* Background colors don’t work with `background-origin`, so use a gradient. */
    background:
        linear-gradient(
                to bottom,
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 1)
            )
            padding-box,
        /* ends at inner border edges */
            conic-gradient(
                from var(--bg-angle) at center,
                transparent,
                #eaab00,
                #bb2236,
                transparent,
                transparent,
                transparent
            )
            border-box; /* extends to outer border edges */

    /* a clear border lets the background gradient shine through */
    border: 3px solid transparent;
}

/* Other styles remain unchanged */
.watLogo {
    display: flex;
    justify-content: center;
}

.watLogo img {
    width: 70%;
    max-width: 700px;
    min-width: 325px;
    height: auto;
    margin: 10px;
    padding-bottom: 10px;
}

.searchBarContainer {
    margin-top: -10px;
    padding-bottom: 20px;
}

.error-container {
    background-color: #f8d7da;
    color: #721c24;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    width: 70%;
    max-width: 500px;
    animation: zoomIn 0.5s ease;
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(0.5); /* Start from smaller size */
    }
    100% {
        opacity: 1;
        transform: scale(1); /* Zoom in to original size */
    }
}

@keyframes fadeInZoomIn {
    0% {
        opacity: 0; /* Start from fully transparent */
        transform: translate(-50%, -50%) scale(0.5); /* Start from smaller size and centered */
    }
    100% {
        opacity: 1; /* End at fully visible */
        transform: translate(-50%, -50%) scale(1); /* Zoom in to original size and centered */
    }
}

.loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.3);
    padding: 20px;
    border-radius: 15px;
    z-index: 9999;
    animation: fadeInZoomIn 0.3s ease;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.radio-buttons {
    display: flex;
    justify-content: center; /* Horizontally center-align the content */
    align-items: center;
    margin-top: -10px;
    padding-bottom: 25px;
}

.radio-text {
    margin-right: 20px; /* Add margin to create space between radio button and text */
}

.radio-buttons input[type='radio'] {
    --c: #2196f3; /* the active color */
    height: 25px;
    width: 25px;
    border: solid 2px #939393;
    padding: 3px;
    background: radial-gradient(farthest-side, var(--c) 94%, #0000) 50%/0 0
        no-repeat content-box;
    border-radius: 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    font-size: inherit;
    transition: 0.3s;
    margin-right: 10px;
}

.radio-buttons input[type='radio']:checked {
    border-color: var(--c);
    background-size: 100% 100%;
}

.radio-buttons input[type='radio']:hover {
    background-color: lightgrey;
}
