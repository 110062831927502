.navbar {
    display: flex;
    padding: 10px 20px;
    box-shadow: 0 2px 80px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    backdrop-filter: blur(8px); /* Apply Gaussian blur effect */
}

@media (max-width: 768px) {
    .navbar {
        padding: 10px 20px; /* Decrease padding on mobile to make navbar shorter */
    }
}

.navbar-container {
    display: flex;
    align-items: center;
    max-width: 1150px;
    width: 100%;
    margin: 0 auto;
}

.navbar-logo img {
    width: 40px;
    height: 40px;
    margin-right: 25px;
    padding-top: 3px;
}

.navbar-company-name {
    font-size: 1.5em;
    font-weight: bold;
    color: white;
    text-decoration: none;
    margin-right: 15px;
}

.nav-link {
    color: #fff;
    margin-left: 40px;
    font-size: 16px;
    text-decoration: none;
    position: relative;
}

.nav-link::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.nav-link:hover::before {
    opacity: 1;
}

.sidebar {
    height: calc(100% - 67px); /* Adjust height to exclude the navbar height */
    width: 200px;
    position: fixed;
    z-index: 1000; /* Sidebar sits below the navbar */
    top: 67px; /* Start below the navbar */
    left: 0;
    background-color: #f0f0f0;
    overflow-x: hidden;
    transform: translateX(-100%);
    transition: transform 0.5s ease;
    padding-top: 0px; /* Remove padding to align with the top */
}

.sidebar.open {
    transform: translateX(0); /* Slide into view */
}

.sidebar a {
    padding: 30px 25px;
    text-decoration: none;
    font-size: 18px;
    color: #000;
    display: block;
    transition: 0.3s;
    border: 1px solid #ddd;
}

.sidebar a:hover {
    background-color: rgba(122, 193, 255, 0.7);
}

.sidebar-toggle {
    background-color: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 25px;
    padding-top: 10px;
    margin-right: 30px;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 66px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 20;
    transition: opacity 0.3s ease;
}
