.success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 120px;

    height: auto; /* Adjusted height to auto for better responsiveness */
}

.property {
    --bg-angle: 0deg;
    --box-size: 150px; /* Set the size of the square box */
}

/* Define animation to rotate the gradient */
@keyframes spin {
    to {
        --bg-angle: 360deg;
    }
}

.success-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    width: 80%;
    max-width: 1120px;
    text-align: center;
    animation:
        spin 2.5s infinite linear running,
        fadeIn 1s ease; /* Add fade-in animation */
}

.success-content {
    /* Background colors don’t work with `background-origin`, so use a gradient. */
    background:
        linear-gradient(
                to bottom,
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 1)
            )
            padding-box,
        /* ends at inner border edges */
            conic-gradient(
                from var(--bg-angle) at center,
                transparent,
                #099773,
                #43b692,
                transparent,
                transparent,
                transparent
            )
            border-box; /* extends to outer border edges */

    /* a clear border lets the background gradient shine through */
    border: 3px solid transparent;
}

.success-return-home {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    height: 50px;
    border-radius: 11px;
    background: linear-gradient(to right, #099773, #43b692);
    margin-top: 20px;
    color: white;
    font-weight: bold;
    transition-duration: 0.4s;
    cursor: pointer;
    position: relative; /* Change to relative to center it within its parent */
}

.success-return-home:active {
    transform: scale(0.8);
}

.success-return-home:hover {
    background: linear-gradient(
        to right,
        #077f61,
        #3aaf82
    ); /* Adjusted colors for hover */
}
