.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    height: auto;
    color: rgb(86, 86, 86);
}

.home-title {
    font-size: clamp(35px, 8vw, 75px);
    color: rgb(86, 86, 86);
    text-align: center;
    position: relative;
    line-height: 1.2; /* Adjust line-height to control spacing */
}

.left-align {
    display: block;
    text-align: left;
    margin-left: 10%;
    position: relative;
}

.center-text {
    display: block;
    text-align: center;
    position: relative;
}

.title-container {
    position: relative;
    display: inline-block;
    text-align: center;
}

@font-face {
    font-family: 'The Bouquet List WOFF2';
    src: url('../../fonts/TheBouquetList.woff2') format('truetype');
    font-weight: bolder;
    font-style: normal;
}

@font-face {
    font-family: 'The Bouquet List WOFF';
    src: url('../../fonts/TheBouquetList.woff') format('truetype');
    font-weight: bolder;
    font-style: normal;
}

.static-handwriting {
    font-family: 'The Bouquet List WOFF2', 'The Bouquet List WOFF';
    font-size: 1.7em;
    position: absolute;
    bottom: -14%; /* Aligns to the bottom of the parent container */
    left: 48.5%; /* Aligns to the left of the parent container */
    padding-right: 30px;
    padding-bottom: 10px;
    transform: translate(-10%, 10%) rotate(-5deg); /* Adjust position and rotation */
    color: transparent;
    background: linear-gradient(to right, #662d8c, #ed1e79);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility; 
    pointer-events: none; /* Make it non-interactive */
    white-space: nowrap; /* Prevent line breaks */
}

.main-text {
    visibility: hidden;
}

.home-description {
    font-size: clamp(17px, 4vw, 20px);
    text-align: center;
    background-color: white; /* Solid white background */
    display: inline; /* Background only applies to the text */
    box-decoration-break: clone; /* Ensures background applies to each line of text separately */
    border-radius: 2px; /* Optional: Adds slight rounding to corners */
}

/* Other styles remain unchanged */

.our-unis {
    margin-top: 0px;
    margin-bottom: -5px;
    background-color: white; /* Solid white background */
    box-decoration-break: clone;
    display: inline-block; /* Switch to inline-block for better control */
    padding: 1px 2px; /* Small padding to create the "little bit out" effect */
}
.background-dots {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    height: 70%;
    background-image: radial-gradient(black 1px, transparent 0);
    background-size: 30px 30px;
    background-position: center; /* Center the dots */
    z-index: 0; /* Ensure it stays behind the content */
    pointer-events: none; /* Make sure it doesn't interfere with user interaction */
    opacity: 0.3;

    /* Sharper fade out effect with more area faded */
    mask-image: radial-gradient(
        circle,
        rgba(0, 0, 0, 1) 10%,
        rgba(0, 0, 0, 0.3) 25%,
        transparent 70%
    );
}

.home-content {
    position: relative;
    z-index: 1; /* Ensure it stays above the dots */
    border-radius: 15px;
    width: 80%;
    max-width: 1120px;
    text-align: center;
    animation: fadeIn 1s ease; /* Add fade-in animation */
}

.under-title {
    width: 70%;
    margin: 20px auto;
    text-align: center;
}

.universities {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 40px;
    margin: 0 auto;
    max-width: 1200px;
}

.swiper-button-prev-custom,
.swiper-button-next-custom {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); /* Center vertically */
    z-index: 10;
    cursor: pointer;
    color: #662d8c; /* Adjust color as needed */
    background: rgba(
        255,
        255,
        255,
        0.7
    ); /* Optional: Background color for better visibility */
    border-radius: 50%;
    padding: 10px;
}

.swiper-button-prev-custom {
    left: -20px; /* Position to the left of the Swiper container */
}

.swiper-button-next-custom {
    right: -20px; /* Position to the right of the Swiper container */
}

.swiper-button-prev-custom:hover,
.swiper-button-next-custom:hover {
    color: #ed1e79; /* Hover color */
    background: rgba(255, 255, 255, 1); /* Change background on hover */
}

.swiper {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    height: 100%; /* Ensure the swiper div takes full height */
}

.uni-box {
    display: flex;
    align-items: center; /* Center the image vertically */
    justify-content: center; /* Center the image horizontally */
    background: white;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    width: 97%;
    max-width: 350px;
    height: 150px; /* Set a fixed height to center the image properly */
    margin: auto; /* Center within the swiper container */
    animation: spin 2.5s infinite linear running;
}

.uni-box {
    /* Background colors don’t work with `background-origin`, so use a gradient. */
    background:
        linear-gradient(
                to bottom,
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 1)
            )
            padding-box,
        /* ends at inner border edges */
            conic-gradient(
                from var(--bg-angle) at center,
                #662d8c,
                /* Start with color A */ #7b4f99,
                /* Slightly lighter transition */ #8b4f9d,
                /* Light transition */ #a26db5,
                /* Moderate transition */ #c13d70,
                /* Peak at color B */ #b0578c,
                /* Slightly darker transition */ #8b4f9d,
                /* Reverse transition */ #7b4f99,
                /* Darker reverse transition */ #662d8c
                    /* End back at color A */
            )
            border-box; /* extends to outer border edges */

    /* a clear border lets the background gradient shine through */
    border: 3px solid transparent;
}

.uni-box:hover {
    transform: scale(1.01);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.waterloo-shield {
    max-width: 150px;
}

.western-shield {
    max-width: 150px;
}

.infogrid-container {
    display: flex; /* Align items side by side */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    width: 96%;
    max-width: 1150px;
    margin: 0 auto; /* Center the container */
}

/* Info grid items */
.infogrid {
    display: block; /* Use flexbox to center content */
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    border-radius: 15px;
    width: calc(100% / 3 - 22px);
    aspect-ratio: 1/1; /* Keep the box square */
    background-color: #fff;
    margin: 10px; /* Add margin to space out the items */
    border: 1px solid #ddd;
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Info grid items */
.infogrid2 {
    display: block; /* Use flexbox to center content */
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    border-radius: 15px;
    width: calc(100% / 3 - 22px);
    aspect-ratio: 1/1; /* Keep the box square */
    background-color: #fff;
    margin: 10px; /* Add margin to space out the items */
    border: 1px solid #ddd;
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.infogrid-content {
    display: flex; /* Use flexbox to center content */
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    text-align: center;
    height: 100%;
    width: 100%; /* Ensure the content takes the full width */
    padding: 20px; /* Add padding inside the infogrid content */
    box-sizing: border-box; /* Include padding in the element's width and height */
}

/* Media query for smaller screens */
@media (max-width: 900px) {
    .infogrid {
        width: calc(50% - 25px); /* Two items per row */
        max-width: 100%;
    }
    .infogrid2 {
        width: calc(100% - 20px); /* Single item per row */
        aspect-ratio: inherit;
        max-width: 100%;
    }
}

@media (max-width: 600px) {
    .infogrid {
        width: calc(100% - 20px); /* Single item per row */
        aspect-ratio: inherit;
        max-width: 100%;
    }
    .infogrid2 {
        width: calc(100% - 20px); /* Single item per row */
        aspect-ratio: inherit;
        max-width: 100%;
    }
}

/* .magnify {
  width: 55px;
  height: 55px;
  position: absolute;
  bottom: -17px;
  right: 2px;
  opacity: 75%;
} */

/* @media (max-width: 790px) {
  .infogrid-2 {
    min-width: 250px;
  }
} */

.how-to-png {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease; /* Smooth transition */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    border-color: #ddd;
    border-width: 2px;
    border-style: solid;
    position: relative;
}

.infogrid-icons {
    padding-top: 10px;
    font-size: clamp(70px, 10vw, 120px);
    margin-bottom: 10px; /* Added margin bottom for spacing */
    color: #662d8c; /* Applied consistent color */
}

.infogrid strong {
    color: #662d8c; /* Applied consistent color */
    font-size: 18px; /* Adjusted font size for better emphasis */
}

.infogrid p {
    color: #333; /* Adjusted text color for better contrast */
    font-size: 16px; /* Adjusted font size for readability */
    line-height: 1.5; /* Increased line height for better readability */
    margin-bottom: 0; /* Removed default margin for cleaner appearance */
}

.infogrid2 strong {
    color: #662d8c; /* Applied consistent color */
    font-size: 18px; /* Adjusted font size for better emphasis */
}

.infogrid2 p {
    color: #333; /* Adjusted text color for better contrast */
    font-size: 16px; /* Adjusted font size for readability */
    line-height: 1.5; /* Increased line height for better readability */
    margin-bottom: 0; /* Removed default margin for cleaner appearance */
}

.how-to-use {
    max-width: 1090px;
    width: 90%;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    text-align: center; /* Center align the content */
    height: auto;
    margin: 12px auto 0; /* Adjusted margin to center vertically */
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-bottom: 50px;
}

.how-to-use * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.wrapper {
    max-width: 1080px;
    margin: 50px auto;
    padding: 0 20px;
    position: relative;
}
.wrapper .center-line {
    position: absolute;
    height: 75%;
    width: 4px;
    left: 50%;
    top: 35px;
    transform: translateX(-50%);
}
.wrapper .row {
    display: flex;
}
.wrapper .row-1 {
    justify-content: flex-start;
}
.wrapper .row-2 {
    justify-content: flex-end;
}
.wrapper .row section {
    border-radius: 15px;
    width: calc(50% - 40px);
    padding: 20px;
    position: relative;
    border: 1px solid #ddd;
    background-color: white;
    text-align: center;
}

@media (min-width: 790px) {
    .how-to-png {
        cursor: zoom-in;
    }
}
@media (max-width: 790px) {
    .wrapper .row section {
        margin-bottom: 20px;
    }
    .wrapper .center-line {
        margin-top: 27px;
    }
    .timeline-container {
        margin-bottom: -50px;
    }
}

.row section .details {
    text-align: center;
}

.wrapper .row section::before {
    position: absolute;
    content: '';
    height: 40px;
    width: 40px;
    background: white;
    top: 28px;
    transform: rotate(45deg);
    z-index: -1;
    background: linear-gradient(to right, #662d8c, #ed1e79);
}

.row-1 section::before {
    right: -7px;
}
.row-2 section::before {
    left: -7px;
}
.row section .icon,
.center-line {
    position: absolute;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    font-size: 17px;
    border-color: transparent;
    background: linear-gradient(to right, #662d8c, #ed1e79);
}

.center-line {
    bottom: 0px;
    transform: translateX(-50%);
    border-radius: 0%;
    background: #ddd;
}
.row-1 section .icon {
    top: 30px;
    right: -60px;
    color: white;
    font-size: x-large;
    font-weight: 800;
    font-style: normal;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row-2 section .icon {
    top: 30px;
    left: -61px;
    color: white;
    font-size: x-large;
    font-weight: 800;
    font-style: normal;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.row section .details,
.row section .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}
.row section .details .title {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
}
.row section .details {
    display: flex; /* Keep using flex display */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    max-width: 80%; /* Limit the maximum width of the container */
    margin: 0 auto; /* Center the container horizontally */
}

.wrapper span {
    text-align: center;
}
.row section p {
    margin: 10px 0 17px 0;
    max-width: 100%;
}
.row section .bottom a {
    text-decoration: none;
    color: #a71111;
    padding: 7px 15px;
    border-radius: 5px;
    /* font-size: 17px; */
    font-weight: 400;
    transition: all 0.3s ease;
}
.row section .bottom a:hover {
    transform: scale(0.97);
}
@media (max-width: 790px) {
    .wrapper .center-line {
        left: 40px;
    }
    .wrapper .row {
        margin: 30px 0 3px 60px;
    }
    .wrapper .row section {
        width: 100%;
    }
    .row-1 section::before {
        left: -7px;
    }
    .row-1 section .icon {
        left: -60px;
    }
}
@media (max-width: 440px) {
    .wrapper .center-line,
    .row section::before,
    .row section .icon {
        display: none;
    }
    .wrapper .row {
        margin: 10px 0;
    }
}

.how-to-use h1 {
    font-size: 36px;
}
.how-to-use h2 {
    margin-bottom: 0.5em;
    font-size: 24px;
}
.how-to-use p {
    font-size: 18px;
}

@media (max-width: 768px) {
    .under-title {
        width: 100%;
    }

    .how-to-use li {
        padding-left: 15px;
    }
}

.dynamic-text {
    background: linear-gradient(to right, #662d8c, #ed1e79);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.get-started-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to right, #662d8c, #ed1e79);
    border: none;
    border-radius: 30px; /* Rounded corners */
    padding: 12px 30px; /* Padding for the button */
    color: #fff; /* White text color */
    font-size: 18px; /* Font size */
    font-weight: bold; /* Bold text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: all 0.3s ease; /* Smooth transition for hover effect */
    box-shadow:
        0 6px 10px rgba(0, 0, 0, 0.1),
        0 2px 4px rgba(0, 0, 0, 0.2),
        inset 0 -1px 1px rgba(0, 0, 0, 0.1); /* Outer and inner shadows for 3D effect */
    position: relative;
    height: 30px;
    width: 140px;
    margin: 50px auto; /* Center horizontally */
}

.get-started-button::before {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border: 1px solid #ddd;
    border-radius: 34px; /* Slightly larger radius than the button */
    background: #ffffff;
    opacity: 0.9;
    z-index: -1;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft outer shadow for the border */
}

.get-started-button:hover {
    background: linear-gradient(to right, #4e236b, #c51a68);

    box-shadow:
        0 4px 8px rgba(0, 0, 0, 0.15),
        0 2px 4px rgba(0, 0, 0, 0.2),
        inset 0 -2px 2px rgba(0, 0, 0, 0.15); /* Adjusted shadow on hover */
}

.home-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    text-align: center;
}

/* Modal Options Container */
.homemodal-options {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    margin-top: 3vh;
    gap: 2vh;
}

/* Logo inside Modal Box */
.homemodal-logo {
    margin-right: 10px;
    height: 90px;
}

.homemodal-description {
    font-size: 20px;
    padding-left: 15px;
}

.choose-uni-modal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.homemodal-content {
    display: flex;
    margin-top: 15px;
    flex-direction: column;
    border: none;
    align-items: center;
    height: 95%;
    width: 100%;
    max-width: 500px;
    max-height: 700px;
    overflow-y: auto; /* Add this line to make the modal content scrollable */
}

.homemodal-background {
    border-radius: 15px;
    position: fixed;
    border: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    height: 85%;
    width: 90%;
    max-width: 500px;
    max-height: 700px;
    animation: modalFadeInUp 0.5s ease;
}

/* Numbered Circles inside the Modal */
.modal-number-circle.icon {
    position: relative;
    width: 30px;
    height: 30px;
    background: linear-gradient(to right, #662d8c, #ed1e79);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
    z-index: 10; /* Ensure it's on top of other content */
}

.image-modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Dark background */
    z-index: 9999;
    opacity: 0;
    animation: fadeIn 0.3s forwards; /* Fade in background */
}

.image-modal-content {
    max-width: 70%;
    max-height: 90%;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    transform: scale(0.5); /* Initial scale for zoom-in effect */
    animation: zoomIn 0.3s forwards;
}

@media (max-width: 700px) {
    .image-modal-content {
        max-width: 90%;
    }
}

.image-modal-content img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

/* Keyframes for zoom-in effect */
@keyframes zoomIn {
    from {
        transform: scale(0.5); /* Start smaller */
    }
    to {
        transform: scale(1); /* End at normal size */
    }
}

.closepng-button {
    z-index: 1000;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 30px;
    color: #fff;
    padding: 5px;
    border: none;
    background: none;
}

.closepng-button:hover {
    color: #ccc;
}

.homemodal-background:focus,
.homemodal-content:focus,
.get-started-button:focus,
.close-button:focus {
    outline: none; /* Removes the default browser focus outline */
    box-shadow: none; /* Optional: Removes any default shadow */
}
