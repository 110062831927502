:root {
    --search-width: 60vw;
}

input#search {
    position: relative;
    color: black;
    font-size: 17px;
    padding: 12px 10px;
    width: var(--search-width);
    max-width: 600px;
    min-width: 260px;
    height: 25px;
    border-radius: 12px;
    border: 1px solid #ddd;
    outline: none;
    backdrop-filter: blur(8px);
    background-color: rgba(255, 255, 255, 1);
    z-index: 12;
    transition:
        border-color 0.3s,
        border-radius 0.3s;
}

input#search:focus {
    border: 1px solid #005fcc;
}

input#search:hover,
input#search:hover ~ ul {
    border-color: #005fcc;
}

.container.dropdown-open input#search:hover {
    border-bottom-color: #ddd;
}

/* When dropdown is expanded, make the bottom border transparent */
.container.dropdown-open input#search {
    border-bottom-color: #ddd; /* Make bottom border transparent */
    border-bottom-left-radius: 0; /* Remove bottom left border radius */
    border-bottom-right-radius: 0; /* Remove bottom right border radius */
}

ul {
    background: rgba(255, 255, 255, 1);
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    max-width: var(--search-width);
    width: 100%;
    margin-top: -50px;
    padding-top: 49px;
    overflow-y: auto;
    max-height: 0;
    list-style: none;
    padding-left: 0;
    transform: translateY(0px);
    will-change: transform;
    backdrop-filter: blur(8px);
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    z-index: -1;
    transition:
        max-height 0.4s ease,
        border-color 0.3s ease,
        border-radius 0.3s ease;
}

ul.drop {
    border-radius: 12px;
}

.container.focused ul {
    border-color: #005fcc;
}

ul.expanded {
    max-height: 600px;
}

ul li {
    font-size: 15px;
    padding: 10px;
    border-top: 1px solid #ddd;
    color: black;
    word-wrap: break-word;
    white-space: normal;
}

ul li:hover {
    background-color: rgba(240, 248, 255, 0.7);
    cursor: pointer;
}

ul li.selected {
    color: #007bff;
}

/* Container for the search bar */
.container {
    position: relative;
    display: inline-block;
    width: 100%;
}

/* Add styling for the search icon */
.search-icon {
    position: absolute;
    top: 28px;
    left: 12px;
    transform: translateY(-50%);
    font-size: 20px;
    color: #999;
    pointer-events: none;
    z-index: 14;
}

input#search {
    padding-left: 40px;
}
