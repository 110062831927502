.footer {
  max-width: 100%;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 10px; /* Reduce padding */
  margin-top: 20px; /* Reduce margin */
  transition: box-shadow 0.5s ease;
}

.footer:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.footer-container {
  max-width: 1200px; /* Adjust to your design needs */
  margin: 0 auto; /* Center the container horizontally */
  padding: 0 20px; /* Add horizontal padding */
}

.footer-columns {
  display: flex;
  justify-content: center; /* Center columns horizontally */
  align-items: flex-start; /* Align items to the top */
  flex-wrap: wrap; /* Allow columns to wrap to the next line */
}

.footer-column {
  flex: 1; /* Distribute space evenly between columns */
  margin-right: 20px; /* Add margin between columns */
}

.footer-column:last-child {
  margin-right: 0; /* Remove margin from last column */
}

.footer-column h3 {
  margin-bottom: 10px; /* Add margin below heading */
}

.footer-column div {
  display: flex;
  flex-direction: column;
}

.footer-column div p {
  margin-bottom: 5px; /* Add margin between paragraphs */
}

.footer-bottom {
  text-align: center;
  margin-top: 50px;
}

.footer-link {
  text-decoration: none;
  color: #424242;
  cursor: pointer;
}

.footer-link:hover {
  text-decoration: underline;
}
