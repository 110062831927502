.accordion {
    display: flex;
    flex-direction: column;
    width: 95%; /* Adjusted width to 80% */
    max-width: 1120px; /* Added max-width for better responsiveness */
    border: 1px solid #ccc;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    overflow: hidden;
    transition: all 0.3s ease;
    margin: 0 auto 20px;
}

@media (max-width: 600px) {
    .accordion {
        width: 100%;
    }
}

.accordion.active {
    border: 1px solid #9bafd9;
    transition: all 0.3s ease;
}

.accordion-header {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    background-color: #fff;
    padding: 1px;
    padding-left: 20px;
    padding-right: 10px;
    cursor: pointer;
}

.accordion-header span {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    border-radius: 50%;
    user-select: none;
    transition: all 0.3s ease;
}

.accordion-header span.active {
    transform: rotate(180deg);
    color: #9bafd9;
}

@keyframes expandAccordion {
    0% {
        max-height: 0;
        opacity: 0;
    }
    100% {
        max-height: 500px; /* Adjust as needed */
        opacity: 1;
    }
}

.accordion-content {
    font-size: 16px;
    text-align: left;
    line-height: 26px;
    padding-left: 20px;
    padding-right: 20px;
    color: #000;
    background-color: #fff;
    border-top: 1px solid #9bafd9;
    overflow: hidden; /* Hide overflow content */
    max-height: 0; /* Start with collapsed content */
    opacity: 0; /* Start with hidden content */
    animation: expandAccordion 1s ease forwards; /* Apply animation */
}
