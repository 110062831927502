/* SectionsStyle.css */

.sections-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    margin: 0 auto;
    max-width: 1200px;
}

.wes-section-box {
    background: white;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    width: calc(40% - 150px);
    min-width: 300px;
    max-width: 90%;
    animation: fadeInUp 0.5s ease;
    position: relative;
}

.wes-section-box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: linear-gradient(to right, #7152a3, #a073d6);
    border-radius: 15px 15px 0 0;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.wes-section-box:hover {
    transform: scale(1.05);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 723px) {
    .wes-section-box {
        min-width: 90%;
    }

    .wes-section-box:hover {
        transform: none;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
}

.clicked {
    background-color: #dfdfdf;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px; /* Add gap between the h3 and status */
}

.section-header p {
    margin: 0;
    line-height: 1.5;
    display: flex;
    align-items: center; /* Center vertically */
}

.details {
    display: flex;
    justify-content: space-between; /* Space between class number and delivery type */
    align-items: center; /* Center them vertically */
}

.details p {
    display: flex;
    align-items: center; /* Align icon and text vertically */
}

.class-number {
    background-color: #f0f0f0; /* Light gray background */
    padding: 8px 12px; /* Padding inside the box */
    border-radius: 8px; /* Rounded corners */
    font-weight: bold; /* Make the text bold */
    font-size: 14px; /* Adjust the font size */
    color: #333; /* Dark text color */
    display: inline-block; /* Make it an inline block for proper spacing */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.details p svg {
    margin-right: 8px; /* Add space between the icon and the text */
}

.wes-button {
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    height: 35px; /* Ensure consistent height */
    padding: 0px 10px; /* Add padding for spacing */
    border-radius: 11px;
    background: linear-gradient(to right, #7152a3, #a073d6);
    margin-top: 0; /* Remove top margin */
    color: white;
    transition-duration: 0.4s;
    cursor: pointer;
    text-align: center; /* Center text */
}

.wes-button p {
    margin: 0;
    line-height: 1.5; /* Ensure the text is centered */
    font-size: 16px;
    font-weight: bold;
    padding-left: 5px; /* Space between icon and text */
}
.proceed-button-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 40px;
}

.checkout {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 190px;
    height: 50px;
    border-radius: 11px;
    background: linear-gradient(to right, #662d8c, #ed1e79);
    margin-top: 10px;
    color: white;
    font-weight: bold;
    transition-duration: 0.4s;
    cursor: pointer;
}

.modal-arrow {
    font-size: 35px;
    line-height: 30px; /* Adjust the line-height to match the font-size */
    color: white; /* Set text color */
}

.stripe-checkout {
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 100%;
    height: 0;
    padding-top: 30px;
    color: white;
    font-weight: bold;
}

.checkout:hover {
    background: linear-gradient(to right, #531f6e, #d71366);
}

.checkout:active {
    transform: scale(0.8);
}

.checkout-align {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    justify-content: center;
}

.payment-images {
    margin-top: 15px;
    width: 275px; /* Set appropriate width */
    height: auto; /* Maintain aspect ratio */
    padding-bottom: 10px;
}

.bell {
    display: flex;
    align-items: center; /* Vertically align items */
    color: white;
    padding-left: 8px;
    font-size: 18px;
}

.bell p {
    display: inline;
    font-size: 16px;
    color: white;
    font-weight: bold;
    padding-left: 5px;
    line-height: 35px;
}

.wes-button:hover {
    background: linear-gradient(to right, #66498f, #9067bf);
}

.wes-button:active {
    transform: scale(0.8);
}

.modal-background {
    border-radius: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    height: 85%;
    width: 90%;
    max-width: 500px;
    max-height: 700px;
    animation: modalFadeInUp 0.5s ease;
}

@keyframes modalFadeInUp {
    0% {
        opacity: 0; /* Start from opacity of 0 */
        transform: translate(-50%, -50%) translateY(20px); /* Start from slightly below center */
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) translateY(0); /* End at centered position */
    }
}

.close-button {
    z-index: 1000;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 30px;
    color: #333;
    padding: 5px;
    border: none;
    background: none;
}

.close-button:hover {
    color: #666;
}

.input-field {
    width: 90%;
    height: 50px;
    max-width: 300px;
    padding: 10px;
    margin-bottom: 15px; /* Add margin between inputs */
    border: 1px solid #ccc;
    border-radius: 11px;
    box-sizing: border-box;
    font-size: 16px;
}

.input-error {
    transition: ease 0.5s;
    box-shadow: 0 0 15px rgba(244, 67, 54, 0.5); /* Optional red glow */
}

.modal-content {
    display: flex;
    margin-top: 15px;
    flex-direction: column;
    align-items: center;
    height: 95%;
    width: 100%;
    max-width: 500px;
    max-height: 700px;
    overflow-y: auto; /* Add this line to make the modal content scrollable */
}

.modal-title {
    padding-top: 40px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.modal-course {
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 20px;
}

.sections-modal-container {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 30px;
    gap: 20px;
    max-width: 1200px;
}

.sections-modal {
    text-align: center;
    display: inline-block;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
    transition: all 0.3s ease;
    width: 70px;
    position: relative;
    /* Apply gradient border */
    border: double 2px transparent; /* Start with a transparent border */
    border-radius: 11px;
    background-image: linear-gradient(white, white),
        radial-gradient(circle at top left, #662d8c, #ed1e79);

    background-origin: border-box;
    background-clip: padding-box, border-box;
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(0.5); /* Start from smaller size */
    }
    100% {
        opacity: 1;
        transform: scale(1); /* Zoom in to original size */
    }
}

.error-sections {
    text-align: center;
    background-color: #f8d7da;
    color: #721c24;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: -10px auto 40px;
    max-width: 250px;
    animation: zoomIn 0.5s ease; /* Use zoomIn animation */
}

.error-email {
    text-align: center;
    background-color: #f8d7da;
    color: #721c24;
    padding: 15px;
    border-radius: 11px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 0 auto 15px;
    max-width: 250px;
    animation: zoomIn 0.5s ease; /* Use zoomIn animation */
}

.terms-link {
    color: blue; /* Change color to make it stand out */
    cursor: pointer; /* Show pointer cursor when hovering */
}

.terms-link:hover {
    text-decoration: underline; /* Underline on hover */
}

.terms-of-service {
    text-align: center; /* Center align the text */
    font-size: 14px; /* Decrease font size */
    margin-top: 20px; /* Add some space between the button and the text */
    max-width: 70%;
}

.service-info {
    padding: 0 40px; /* Add padding on the left and right sides */
    font-family: Arial, sans-serif; /* Use a nice font */
    line-height: 1.6; /* Improve readability with increased line height */
}

.service-info p {
    margin-bottom: 20px; /* Add some space between paragraphs */
}

.conditions-logo {
    height: 10%;
    padding-top: 20px;
    margin-bottom: -10px;
}

.stripe-loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.3);
    padding: 20px;
    border-radius: 15px;
    z-index: 9999;
    animation: fadeInZoomIn 0.3s ease;
}

/* Styling for the checkmark icon */
.checkmark-icon {
    color: #000; /* Blue color for the checkmark */
    font-size: 24px;
    margin-left: 8px;
    animation: growIn 0.3s ease-out forwards;
}

/* Container for section number and checkmark */
.section-info {
    display: flex;
    align-items: center; /* Align items vertically */
}

@keyframes growIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    80% {
        transform: scale(1.1);
        opacity: 1;
    }
    100% {
        transform: scale(1);
    }
}

.wes-number-circle.icon {
    position: relative;
    min-width: 30px;
    min-height: 30px;
    background: linear-gradient(to right, #66498f, #9067bf);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 18px;
    margin-right: 10px;
    font-weight: bold;
    z-index: 10; /* Ensure it's on top of other content */
}

.wes-sections-description {
    font-size: 18px;
    padding-left: 15px;
}

.wes-section-instructions-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.wes-section-instructions {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 1120px;
    max-height: 0; /* Initially set height to 0 */
    overflow: hidden; /* Hide the overflow content */
    transition: max-height 0.5s ease-in-out; /* Smooth transition for height change */
}

.wes-section-instructions.open {
    max-height: 500px; /* Set max-height large enough to contain the content */
}

.wes-section-instructions.closed {
    max-height: 0;
    transition: max-height 0.5s ease-out; /* Smooth transition for height change */
}

.wes-instruction-step {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px; /* Adjust spacing between steps */
}

.wes-help-button-container {
    display: flex; /* Use flexbox to align items */
    justify-content: right; /* Center the button horizontally */
    width: 90%;
    max-width: 1120px;
    margin: 0 auto 10px auto; /* Center the container itself and add bottom margin */
    position: relative;
    top: 0; /* Ensure it stays at the top */
    animation: fadeInUp 0.5s ease;
}

.wes-help-button {
    position: relative;
    min-width: 30px;
    min-height: 30px;
    background: linear-gradient(to right, #66498f, #9067bf);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
    font-weight: bold;
    z-index: 10; /* Ensure it's on top of other content */
}


